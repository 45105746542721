<script>
  import {createEventDispatcher} from "svelte";
  import {
    Button,
    Form,
    FormGroup,
    NumberInput,
    TextInput,
    Grid,
    Row,
    Column,
    DatePicker,
    DatePickerInput,
  } from "carbon-components-svelte";
  import IconAdd from "carbon-icons-svelte/lib/Add32";
  import Sveltidator from "svelte-validation";
  import {requiredStringConstraint, orgNameConstraints} from "../validation";

  const dispatch = createEventDispatcher();

  export let organization = {name: "", dueAmount: 0, dueDateTmp: new Date(), number: "", billingContact: {}};

  let sv = Sveltidator(
    () => {
      return {
        name: organization.name,
      };
    },
    {
      name: orgNameConstraints,
      number: requiredStringConstraint,
      billingContactFirstName: requiredStringConstraint,
      billingContactLastName: requiredStringConstraint,
      billingContactMobileNumber: requiredStringConstraint,
      billingContactEmailAddress: requiredStringConstraint,
    }
  );

  const iAdd = {
    class: undefined,
    skeleton: false,
    render: IconAdd,
    title: "Add",
    tabindex: "0",
    focusable: false,
    style: undefined,
  };

  async function onFormSubmit(e) {
    e.preventDefault();
    sv.reset();
    organization.dueAmount = parseInt(organization.dueAmount);
    organization.dueDate = new Date(organization.dueDateTmp).valueOf() / 1000;
    dispatch("save", organization);

    /*
    sv.setBackendErrors({
      username: ["Username is already taken"],
      password: ["Password is not correct"],
    });
    */
    /*
    toast({
      message: "Hello There",
      position: "bottom-center",
      type: "is-success",
      dismissible: true,
      animate: { in: "fadeIn", out: "fadeOut" },
    });
    */
    //console.log(loginForm);

    //userStore.authenticated();
    //$goto("/");
  }
</script>

<Form on:submit={onFormSubmit}>
  <Grid>
    <Row>
      <Column sm={4} md={4} lg={4}>
        <h4>Basis Details</h4>
        <FormGroup>
          <TextInput
            bind:value={organization.name}
            invalid={$sv.invalid.name}
            invalidText={$sv.invalid.name}
            labelText="Organization Name"
            placeholder="example: Acme, Inc." />
        </FormGroup>
        <FormGroup>
          <TextInput
            bind:value={organization.number}
            invalid={$sv.invalid.number}
            invalidText={$sv.invalid.number}
            labelText="Organization Number or ID"
            placeholder="example: 001982445" />
        </FormGroup>
        <FormGroup>
          <NumberInput
            min={0}
            bind:value={organization.dueAmount}
            invalid={$sv.invalid.dueAmount}
            invalidText={$sv.invalid.dueAmount}
            label="Monthly amount"
            placeholder="example: 59.00" />
        </FormGroup>
        <FormGroup>
          <DatePicker datePickerType="single" bind:value={organization.dueDateTmp}>
            <DatePickerInput labelText="Due date" placeholder="mm/dd/yyyy" />
          </DatePicker>
        </FormGroup>
      </Column>
      <Column sm={4} md={4} lg={4}>
        <h4>Address</h4>
        <FormGroup>
          <TextInput
            bind:value={organization.address}
            invalid={$sv.invalid.address}
            invalidText={$sv.invalid.address}
            labelText="Address"
            placeholder="example: 217 Victory Avenue." />
        </FormGroup>
        <FormGroup>
          <TextInput
            bind:value={organization.city}
            invalid={$sv.invalid.city}
            invalidText={$sv.invalid.city}
            labelText="City"
            placeholder="example: New York" />
        </FormGroup>
        <FormGroup>
          <TextInput
            bind:value={organization.postalCode}
            invalid={$sv.invalid.postalCode}
            invalidText={$sv.invalid.postalCode}
            labelText="Postal Code"
            placeholder="example: 19801" />
        </FormGroup>
      </Column>
      <Column sm={4} md={4} lg={4}>
        <h4>Billing Contact</h4>
        <FormGroup>
          <TextInput
            bind:value={organization.billingContact.firstName}
            invalid={$sv.invalid.billingContactFirstName}
            invalidText={$sv.invalid.billingContactFirstName}
            labelText="First Name"
            placeholder="example: John" />
        </FormGroup>
        <FormGroup>
          <TextInput
            bind:value={organization.billingContact.lastName}
            invalid={$sv.invalid.billingContactLastName}
            invalidText={$sv.invalid.billingContactLastName}
            labelText="Last Name"
            placeholder="example: Doe" />
        </FormGroup>
        <FormGroup>
          <TextInput
            bind:value={organization.billingContact.mobileNumber}
            invalid={$sv.invalid.billingContactMobileNumber}
            invalidText={$sv.invalid.billingContactMobileNumber}
            labelText="Mobile Phone Number"
            placeholder="example: (234) 656-2334" />
        </FormGroup>
        <FormGroup>
          <TextInput
            bind:value={organization.billingContact.emailAddress}
            invalid={$sv.invalid.billingContactEmailAddress}
            invalidText={$sv.invalid.billingContactEmailAddress}
            labelText="Email Addresss"
            placeholder="example: john.doe@example.com" />
        </FormGroup>
      </Column>
    </Row>
    <Row>
      <Column sm={4}>
        <FormGroup>
          <Button type="submit" disabled={!$sv.isFormValid} icon={IconAdd}>Create</Button>
        </FormGroup>
      </Column>
    </Row>
  </Grid>
</Form>
