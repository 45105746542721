import {getValidationMessage} from "./i18n";

const usernameConstraints = {
  presence: true,
  email: {
    message: () => getValidationMessage("email"),
  },
};

const passwordConstraints = {
  presence: true,
  length: {
    minimum: 6,
    message: () => getValidationMessage("password"),
  },
};

const orgNameConstraints = {
  presence: true,
  length: {
    minimum: 2,
    message: () => getValidationMessage("orgName"),
  },
};

const outletNameConstraints = {
  presence: true,
  length: {
    minimum: 1,
    message: () => getValidationMessage("outletName"),
  },
};

const requiredStringConstraint = {
  presence: true,
  length: {
    minimum: 1,
    message: () => getValidationMessage("requiredString"),
  },
};

export {requiredStringConstraint, usernameConstraints, passwordConstraints, orgNameConstraints, outletNameConstraints};
