const ERR_GENERIC = 100;
const ERR_BAD_AUTH = 102;
const ERR_SESSION_NOT_FOUND = 109;
const ERR_INCORRECT_PASSWORD = 201;

export {
    ERR_GENERIC,
    ERR_BAD_AUTH,
    ERR_SESSION_NOT_FOUND,
    ERR_INCORRECT_PASSWORD
}