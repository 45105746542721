<script>
  import {
    Grid,
    Column,
    Row,
    Tile,
    SkeletonPlaceholder,
  } from "carbon-components-svelte";

  export let items = [];
</script>

<style type="text/scss">
  @import "@carbon/type/scss/scale";
  @import "@carbon/type/scss/font-family";
  @import "@carbon/layout/scss/spacing";
  .tileH {
    @include carbon--type-scale(12);
    margin-bottom: $spacing-05;
  }
  .tileText {
    @include carbon--type-scale(4);
    @include carbon--font-weight("light");
  }
  :global(.tile-skeleton-ph) {
    width: 3.375rem !important;
    height: 3.375rem !important;
    margin-bottom: $spacing-05 !important;
  }
</style>

<Grid>
  <Row>
    {#each items as item}
      <Column>
        <Tile>
          {#if item.value !== undefined}
            <div class="tileH">{item.value}</div>
          {:else}
            <SkeletonPlaceholder class="tile-skeleton-ph" />
          {/if}
          <span class="tileText">{item.title}</span>
        </Tile>
      </Column>
    {/each}
  </Row>
</Grid>
