const apiURL =
  process.env.NODE_ENV === "production"
    ? "https://partner-api.avantmenu.com/v1/admin"
    : "http://192.168.1.30:8000/v1/admin";

const api = {
  async login(payload) {
    return fetch(apiURL + "/public/Authenticate", makeOptions(payload));
  },
  async logout() {
    return fetch(apiURL + "/private/Logout", makeOptions());
  },
  async getUserInfo(payload) {
    return fetch(apiURL + "/private/GetUserInfo", makeOptions(payload));
  },
  async getDashboardStats() {
    return fetch(apiURL + "/private/GetDashboardStats", makeOptions());
  },
  async getOrganizationStats(id) {
    return fetch(apiURL + "/private/GetOrganizationStats", makeOptions(id));
  },
  async saveOrganization(payload) {
    return fetch(apiURL + "/private/SaveOrganization", makeOptions(payload));
  },
  async saveOutlet(payload) {
    return fetch(apiURL + "/private/SaveOutlet", makeOptions(payload));
  },
  async saveOutletUser(payload) {
    return fetch(apiURL + "/private/SaveOutletUser", makeOptions(payload));
  },
  async addUsersToOutlet(payload) {
    return fetch(apiURL + "/private/AddUsersToOutlet", makeOptions(payload));
  },
  async getOrganization(payload) {
    return fetch(apiURL + "/private/GetOrganization", makeOptions(payload));
  },
  async getOrganizations(payload) {
    return fetch(apiURL + "/private/GetOrganizations", makeOptions(payload));
  },
  async getOrganizationUsers(payload) {
    return fetch(apiURL + "/private/GetOrganizationUsers", makeOptions(payload));
  },
  async getOrganizationUsersNotInOutlet(payload) {
    return fetch(apiURL + "/private/GetOrganizationUsersNotInOutlet", makeOptions(payload));
  },
  async getOutlets(payload) {
    return fetch(apiURL + "/private/GetOutlets", makeOptions(payload));
  },
  async getOutlet(payload) {
    return fetch(apiURL + "/private/GetOutlet", makeOptions(payload));
  },
  async getOutletUsers(id) {
    return fetch(apiURL + "/private/GetOutletUsers", makeOptions(id));
  },
  async getOutletStats(id) {
    return fetch(apiURL + "/private/GetOutletStats", makeOptions(id));
  },
  async getBreadcrumbTitle(payload) {
    return fetch(apiURL + "/private/GetBreadcrumbTitle", makeOptions(payload));
  },
};

function makeOptions(payload) {
  return {
    method: "post",
    headers: makeHeaders(),
    body: JSON.stringify(payload),
  };
}

function makeHeaders() {
  return {
    "content-type": "application/json",
    "X-Session-Token": window.localStorage.getItem("amp_token"),
  };
}

export default api;
