<script>
  import {
    Accordion,
    AccordionItem,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Checkbox,
    ComboBox,
    ContentSwitcher,
    Form,
    FormGroup,
    HeaderNav,
    HeaderNavItem,
    HeaderNavMenu,
    Select,
    SelectItem,
    Switch,
    TextInput,
    TextArea,
    ToastNotification,
    Toggle,
    Header,
    SelectableTile,
    SideNav,
    SideNavItems,
    SideNavLink,
    StructuredList,
    StructuredListBody,
    StructuredListRow,
    StructuredListCell,
    Content,
    Grid,
    Row,
    Column,
  } from "carbon-components-svelte";
  import { createEventDispatcher } from "svelte";
  import IconAdd from "carbon-icons-svelte/lib/Add32";
  import SvelteValidation from "svelte-validation";
  import { usernameConstraints, requiredStringConstraint } from "../validation";
  import backend from "../backend";
  import { formatErrors } from "../utils";

  const dispatch = createEventDispatcher();

  export let organizationUniqueID = undefined;
  export let outlet = { name: "" };

  let user = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
  };

  let userSwitcherIndex = 0;

  let sv = SvelteValidation(
    () => {
      let shared = {
        name: outlet.name,
      };
      let option1 = {
        userFirstName: user.firstName,
        userLastName: user.lastName,
        userEmailAddress: user.emailAddress,
      };
      let option2 = {};
      if (userSwitcherIndex == 0) {
        console.log({ ...shared, ...option1 });
        return { ...shared, ...option1 };
      } else {
        return { ...shared, ...option2 };
      }
    },
    {
      name: requiredStringConstraint,
      userFirstName: requiredStringConstraint,
      userLastName: requiredStringConstraint,
      userEmailAddress: usernameConstraints,
    }
  );

  const iAdd = {
    class: undefined,
    skeleton: false,
    render: IconAdd,
    title: "Add",
    tabindex: "0",
    focusable: false,
    style: undefined,
  };

  async function onFormSubmit(e) {
    e.preventDefault();
    sv.reset();
    dispatch("save", outlet);
  }
</script>

<Form on:submit={onFormSubmit}>
  <Grid>
    <Row>
      <Column sm={4} md={4} lg={4}>
        <h4>Basis Details</h4>
        <FormGroup>
          <TextInput
            bind:value={outlet.name}
            invalid={$sv.invalid.name}
            invalidText={$sv.invalid.name}
            labelText="Outlet Name"
            placeholder="example: Acme Burgers" />
        </FormGroup>
      </Column>
      <Column sm={4} md={4} lg={4}>
        <h4>Address</h4>
        <FormGroup>
          <TextInput
            bind:value={outlet.address}
            invalid={$sv.invalid.address}
            invalidText={$sv.invalid.address}
            labelText="Address"
            placeholder="example: 217 Victory Avenue." />
        </FormGroup>
        <FormGroup>
          <TextInput
            bind:value={outlet.city}
            invalid={$sv.invalid.city}
            invalidText={$sv.invalid.city}
            labelText="City"
            placeholder="example: New York" />
        </FormGroup>
        <FormGroup>
          <TextInput
            bind:value={outlet.postalCode}
            invalid={$sv.invalid.postalCode}
            invalidText={$sv.invalid.postalCode}
            labelText="Postal Code"
            placeholder="example: 19801" />
        </FormGroup>
      </Column>
      <Column sm={4} md={4} lg={4}>
        <h4>Outlet User</h4>
        <FormGroup>
          <ContentSwitcher bind:selectedIndex={userSwitcherIndex}>
            <Switch>Create New User</Switch>
            <Switch>Add Existing User</Switch>
          </ContentSwitcher>
        </FormGroup>
        {#if userSwitcherIndex === 0}
          <FormGroup>
            <TextInput
              bind:value={user.firstName}
              invalid={$sv.invalid.userFirstName}
              invalidText={$sv.invalid.userFirstName}
              labelText="First Name"
              placeholder="example: John" />
          </FormGroup>
          <FormGroup>
            <TextInput
              bind:value={user.lastName}
              invalid={$sv.invalid.userLastName}
              invalidText={$sv.invalid.userLastName}
              labelText="Last Name"
              placeholder="example: Doe" />
          </FormGroup>
          <FormGroup>
            <TextInput
              bind:value={user.mobileNumber}
              invalid={$sv.invalid.userMobileNumber}
              invalidText={$sv.invalid.userMobileNumber}
              labelText="Mobile Phone Number"
              placeholder="example: (234) 656-2334" />
          </FormGroup>
          <FormGroup>
            <TextInput
              bind:value={user.emailAddress}
              invalid={$sv.invalid.userEmailAddress}
              invalidText={$sv.invalid.userEmailAddress}
              labelText="Email Address"
              placeholder="example: john.doe@example.com" />
          </FormGroup>
          <FormGroup>
            <TextInput
              bind:value={user.password}
              invalid={$sv.invalid.userPassword}
              invalidText={$sv.invalid.userPassword}
              labelText="Password"
              placeholder="minimum 6 chars" />
          </FormGroup>
        {:else}
          {#await backend.getOrganizationUsers(organizationUniqueID)}
            wait...
          {:then users}
            <div role="group" aria-label="selectable tiles">
              {#each users as user, i}
                <SelectableTile id="tile-{i}" name="tiles" value={user.id}>
                  {user.firstName}
                  {user.firstName}
                </SelectableTile>
              {/each}
            </div>
          {/await}
        {/if}
      </Column>
    </Row>
    <Row>
      <Column sm={4}>
        <FormGroup>
          <Button type="submit" disabled={!$sv.isFormValid} icon={IconAdd}>
            Create
          </Button>
        </FormGroup>
      </Column>
    </Row>
  </Grid>
</Form>
