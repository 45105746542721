import {register, init, locale, getLocaleFromNavigator} from "svelte-i18n";

function initi18n() {
  register("en", () => import("./langs/en.json"));
  register("ge", () => import("./langs/ge.json"));

  init({
    fallbackLocale: "en",
    //initialLocale: getLocaleFromNavigator(),
    initialLocale: "ge",
  });
}

const BACKEND_ERRORS = {
  en: {
    emailAddress: {
      107: "User not found",
    },
    password: {
      201: "Password is not correct",
    },
  },
  ge: {
    emailAddress: {
      107: "მომხმარებელი არ მოიძებნა",
    },
    password: {
      201: "პაროლი არასწორია",
    },
  },
};

const VALIDATION_MESSAGES = {
  en: {
    email: "has to be a valid email address",
    password: "must be at least 6 characters",
    orgName: "must be at least 2 characters",
    outletName: "must be at least 1 character",
    requiredString: "must be at least 1 character",
  },
  ge: {
    email: "უნდა იყოს ვალიდური ელ-ფოსტის მისამართი",
    password: "უნდა შედგებოდეს მინიმუმ 6 სიმბოლოსგან",
    orgName: "უნდა შედგებოდეს მინიმუმ 2 სიმბოლოსგან",
    outletName: "უნდა შედგებოდეს მინიმუმ 1 სიმბოლოსგან",
    requiredString: "უნდა შედგებოდეს მინიმუმ 1 სიმბოლოსგან",
  },
};

let validationMessages = {};
let backendErrors = {};

locale.subscribe((v) => {
  if (v) {
    validationMessages = VALIDATION_MESSAGES[v];
    backendErrors = BACKEND_ERRORS[v];
  }
});

const getValidationMessage = function (key) {
  console.debug("getValidationMessage", key);
  return validationMessages[key];
};

const getBackendError = function (detail, code) {
  let tmp = backendErrors[detail];
  if (!tmp) return "";
  return tmp[code];
};

export {initi18n, getValidationMessage, getBackendError};
