<script>
  import { url, route, params } from "@sveltech/routify";
  import { breadcrumbsStore } from "../../stores";
  import { generateBreadcrumbs } from "../../utils";
  import PageTitle from "../../components/PageTitle.svelte";

  breadcrumbsStore.set(generateBreadcrumbs($route, $params));
</script>

<PageTitle>Account</PageTitle>

Coming soon...
