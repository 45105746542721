<script>
  import {
    Link,
    Grid,
    Column,
    Row,
    DataTableSkeleton,
    DataTable,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableBody,
    TableRow,
  } from "carbon-components-svelte";
  import IconAdd from "carbon-icons-svelte/lib/Add32";
  import {onMount, onDestroy} from "svelte";
  import {params, route, goto} from "@sveltech/routify";
  import backend from "../../../../backend";
  import {breadcrumbsStore, toolbarStore} from "../../../../stores";
  import {generateBreadcrumbs} from "../../../../utils";
  import PageTitle from "../../../../components/PageTitle.svelte";
  import EmptyState from "../../../../components/EmptyState.svelte";

  const headers = [
    {key: "uniqueID", value: "Unique ID"},
    {key: "name", value: "Name"},
    {key: "address", value: "Address"},
    {key: "city", value: "City"},
  ];

  const skeletonHeaders = headers.filter((x) => x.key !== "uniqueID").map((x) => x.value);

  let rows;
  let organizationUniqueID;

  onMount(() => {
    breadcrumbsStore.set(generateBreadcrumbs($route, $params));

    organizationUniqueID = $params.organization;

    backend
      .getOutlets({
        filter: organizationUniqueID,
      })
      .then((v) => {
        rows = v.rows;
      });

    toolbarStore.set([
      {
        title: "Add Outlet",
        icon: IconAdd,
        action: () => {
          $goto("/organizations/" + $params["organization"] + "/outlets/create");
        },
      },
    ]);
  });

  onDestroy(() => {
    rows = null;
    toolbarStore.reset();
  });
</script>

<PageTitle>Outlets</PageTitle>

<Grid>
  <Row>
    <Column>
      {#if rows}
        {#if rows.length > 0}
          <DataTable {...$$props} {rows} {headers} let:props>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {#each props.headers.filter((x) => x.key != 'uniqueID') as header, i (header.key)}
                      <TableHeader>{header.value}</TableHeader>
                    {/each}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {#each props.rows as row, i}
                    <TableRow>
                      {#each row.cells as cell, j}
                        {#if j === 1}
                          <TableCell>
                            <Link href={'/organizations/' + organizationUniqueID + '/outlets/' + row.cells[0].value}>
                              {row.cells[1].value}
                            </Link>
                          </TableCell>
                        {:else if headers[j].key !== 'uniqueID'}
                          <TableCell>{headers[j].format ? headers[j].format(cell.value) : cell.value || ''}</TableCell>
                        {/if}
                      {/each}
                    </TableRow>
                  {/each}
                </TableBody>
              </Table>
            </TableContainer>
          </DataTable>
        {:else}
          <EmptyState
            on:primaryAction={() => {
              $goto(`/organizations/${organizationUniqueID}/outlets/create`);
            }}
            title="No outlets found"
            body="Click <strong>Create Outlet</strong> button to create a new outlet."
            primaryActionTitle="Create Outlet"
            primaryActionIcon={IconAdd} />
        {/if}
      {:else}
        <DataTableSkeleton headers={skeletonHeaders} />
      {/if}
    </Column>
  </Row>
</Grid>
