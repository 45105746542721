<script>
  import {
    Link,
    DataTableSkeleton,
    DataTable,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableBody,
    TableRow,
    Grid,
    Column,
    Row,
  } from "carbon-components-svelte";
  import {onMount, onDestroy} from "svelte";
  import IconAdd from "carbon-icons-svelte/lib/Add32";
  import {route, params, goto} from "@sveltech/routify";
  import backend from "../../backend";
  import {breadcrumbsStore, toolbarStore} from "../../stores";
  import {generateBreadcrumbs} from "../../utils";
  import PageTitle from "../../components/PageTitle.svelte";
  import EmptyState from "../../components/EmptyState.svelte";

  const headers = [
    {key: "name", value: "Name"},
    {key: "uniqueID", value: "Unique ID"},
    {key: "number", value: "Reg. Number."},
    {key: "dueDate", value: "Due Date"},
    {key: "dueAmount", value: "Due Amount"},
  ];

  const skeletonHeaders = headers.filter((x) => x.key !== "uniqueID").map((x) => x.value);

  let rows;

  onMount(() => {
    //dayjs.extend(calendar);

    breadcrumbsStore.set(generateBreadcrumbs($route, $params));

    backend.getOrganizations().then((v) => {
      rows = v.rows;
    });

    toolbarStore.set([
      {
        title: "Add Organization",
        icon: IconAdd,
        action: () => {
          $goto("/organizations/create");
        },
      },
    ]);
  });

  onDestroy(() => {
    rows = null;
    toolbarStore.reset();
  });
</script>

<PageTitle>Organizations</PageTitle>

<Grid>
  <Row>
    <Column>
      {#if rows}
        {#if rows.length > 0}
          <DataTable {...$$props} {rows} {headers} let:props>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {#each props.headers.filter((x) => x.key != 'uniqueID') as header, i (header.key)}
                      <TableHeader>{header.value}</TableHeader>
                    {/each}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {#each props.rows as row, i}
                    <TableRow>
                      <TableCell>
                        <Link href={'/organizations/' + row.cells[1].value}>{row.cells[0].value}</Link>
                      </TableCell>
                      <TableCell>{row.cells[2].value}</TableCell>
                      <TableCell>{dayjs(row.cells[3].value * 1000).format('MMM D, YYYY')}</TableCell>
                      <TableCell>{numeral(row.cells[4].value).format('0,0.00')}</TableCell>
                    </TableRow>
                  {/each}
                </TableBody>
              </Table>
            </TableContainer>
          </DataTable>
        {:else}
          <EmptyState
            on:primaryAction={() => {
              $goto('/organizations/create');
            }}
            title="No organizations found"
            body="Click <strong>Create Organization</strong> button to create a new organization."
            primaryActionTitle="Create Organization"
            primaryActionIcon={IconAdd} />
        {/if}
      {:else}
        <DataTableSkeleton headers={skeletonHeaders} />
      {/if}
    </Column>
  </Row>
</Grid>
