<script>
  import {
    Button,
    ContentSwitcher,
    Form,
    FormGroup,
    Switch,
    PasswordInput,
    TextInput,
    SelectableTile,
    Grid,
    Row,
    Column,
  } from "carbon-components-svelte";
  import {createEventDispatcher} from "svelte";
  import IconAdd from "carbon-icons-svelte/lib/Add32";
  import SvelteValidation from "svelte-validation";
  import {usernameConstraints, requiredStringConstraint} from "../validation";
  import backend from "../backend";
  import {formatErrors} from "../utils";

  export let outletUniqueId;

  const dispatch = createEventDispatcher();

  let userSwitcherIndex = 0;
  let selectedUsers = {};

  let user = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
  };

  let sv = SvelteValidation(
    () => {
      let option1 = {
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.emailAddress,
      };
      let option2 = {};
      if (userSwitcherIndex == 0) {
        return {...option1};
      } else {
        return {...option2};
      }
    },
    {
      firstName: requiredStringConstraint,
      lastName: requiredStringConstraint,
      emailAddress: usernameConstraints,
    }
  );

  function onFormSubmit(e) {
    e.preventDefault();
    dispatch("save", user);
  }

  function onClickAddSelectedUsers() {
    let tmp = [];
    for (let key in selectedUsers) {
      if (selectedUsers[key]) tmp.push(key);
    }
    dispatch("addSelectedUsers", tmp);
  }
</script>

<Form on:submit={onFormSubmit}>
  <Grid>
    <Row>
      <Column>
        <FormGroup>
          <ContentSwitcher bind:selectedIndex={userSwitcherIndex}>
            <Switch>Create New User</Switch>
            <Switch>Add Existing User</Switch>
          </ContentSwitcher>
        </FormGroup>
      </Column>
    </Row>
  </Grid>

  {#if userSwitcherIndex === 0}
    <Grid>
      <Row>
        <Column sm={4} md={4} lg={4}>
          <FormGroup>
            <TextInput
              bind:value={user.firstName}
              invalid={$sv.invalid.firstName}
              invalidText={$sv.invalid.firstName}
              labelText="First Name"
              placeholder="example: John" />
          </FormGroup>
        </Column>
        <Column sm={4} md={4} lg={4}>
          <FormGroup>
            <TextInput
              bind:value={user.lastName}
              invalid={$sv.invalid.lastName}
              invalidText={$sv.invalid.lastName}
              labelText="Last Name"
              placeholder="example: Doe" />
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column sm={4} md={4} lg={4}>
          <FormGroup>
            <TextInput
              bind:value={user.emailAddress}
              invalid={$sv.invalid.userEmailAddress}
              invalidText={$sv.invalid.userEmailAddress}
              labelText="Email Address"
              placeholder="example: john.doe@example.com" />
          </FormGroup>
        </Column>
        <Column sm={4} md={4} lg={4}>
          <FormGroup>
            <PasswordInput
              bind:value={user.password}
              invalid={$sv.invalid.userPassword}
              invalidText={$sv.invalid.userPassword}
              labelText="Password"
              placeholder="leave empty to autogenerate." />
          </FormGroup>
        </Column>
        <Column sm={4} md={4} lg={4}>
          <FormGroup>
            <TextInput
              bind:value={user.mobileNumber}
              invalid={$sv.invalid.userMobileNumber}
              invalidText={$sv.invalid.userMobileNumber}
              labelText="Mobile Phone Number"
              placeholder="example: (234) 656-2334" />
          </FormGroup>
        </Column>
      </Row>
      <Row />
    </Grid>
    <Grid>
      <Row>
        <Column sm={4}>
          <FormGroup>
            <Button type="submit" disabled={!$sv.isFormValid}>Create New User</Button>
          </FormGroup>
        </Column>
      </Row>
    </Grid>
  {:else}
    {#await backend.getOrganizationUsersNotInOutlet(outletUniqueId)}
      wait...
    {:then users}
      {#if users.length > 0}
        <Grid>
          <Row>
            <Column>
              <div role="group" aria-label="selectable tiles">
                {#each users as user, i}
                  <SelectableTile
                    id="tile-{i}"
                    name="tiles"
                    value={user.id}
                    bind:selected={selectedUsers[user.uniqueId]}>
                    {user.firstName}
                    {user.lastName}
                  </SelectableTile>
                {/each}
              </div>
            </Column>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Column sm={4}>
              <FormGroup>
                <Button
                  on:click={onClickAddSelectedUsers}
                  type="button"
                  disabled={Object.values(selectedUsers).filter((x) => x).length === 0}>
                  Add Selected Users
                </Button>
              </FormGroup>
            </Column>
          </Row>
        </Grid>
      {:else}
        <div class="text-center">There are no users in this organization that can be added to this outlet.</div>
      {/if}
    {/await}
  {/if}
</Form>
