import backend from "./backend";

const ROUTE_TITLES = {
  "/organizations": "Organizations",
  "/organizations/create": "Create Organization",
  "/organizations/:organization/outlets": "Outlets",
  "/organizations/:organization/outlets/create": "Create Outlet",
  "/organizations/:organization/outlets/:outlet/users": "Users",
  "/organizations/:organization/outlets/:outlet/users/create": "Create User",
  "/organizations/:organization/invoices": "Invoices",
  "/change-password": "Change Password",
  "/settings": "Settings",
};

function generateBreadcrumbs(route, params) {
  const routeComps = route.path.split("/");
  let items = [];
  let path = "";
  let routePath = "";
  let title;
  for (let i = 0; i < routeComps.length; i++) {
    if (routeComps[i] === "") {
      items.push({
        title: new Promise((resolutionFunc, rejectionFunc) => {
          resolutionFunc({
            title: "Dashboard",
          });
        }),
        url: "/",
      });
      continue;
    }
    if (routeComps[i] && routeComps[i] !== "" && routeComps[i] !== "index") {
      if (routeComps[i].startsWith(":")) {
        const p = params[routeComps[i].substring(1)];
        const res = backend.getBreadcrumbTitle({
          kind: routeComps[i],
          uniqueID: p,
        });
        title = res;
        path = path + "/" + p;
        routePath = routePath + "/" + routeComps[i];
      } else {
        path = path + "/" + routeComps[i];
        routePath = routePath + "/" + routeComps[i];
        title = new Promise((resolutionFunc, rejectionFunc) => {
          resolutionFunc({
            title: ROUTE_TITLES[routePath],
          });
        });
      }
      items.push({
        title,
        url: path,
      });
    }
  }
  return items;
}

function getRouteTitle(routeName) {
  return ROUTE_TITLES[routeName];
}

function countBreadcrumbs(route) {
  return route.path.split("/").length - 1;
}

function formatErrors(v) {
  if (!v) return;
  return v.join("<br/>");
}

export {countBreadcrumbs, generateBreadcrumbs, getRouteTitle, formatErrors};
