<script>
  import {
    DataTableSkeleton,
    DataTable,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableBody,
    TableRow,
    Grid,
    Column,
    Row,
  } from "carbon-components-svelte";
  import { onMount, onDestroy } from "svelte";
  import IconAdd from "carbon-icons-svelte/lib/Add32";
  import { params, route, goto } from "@sveltech/routify";
  import backend from "../../../../backend";
  import { breadcrumbsStore, toolbarStore } from "../../../../stores";
  import { generateBreadcrumbs } from "../../../../utils";
  import PageTitle from "../../../../components/PageTitle.svelte";
  import OutletEditor from "../../../../components/OutletEditor.svelte";

  let organizationUniqueId;

  onMount(() => {
    breadcrumbsStore.set(generateBreadcrumbs($route, $params));
    organizationUniqueId = $params.organization;
  });

  async function onSaveOutlet(e) {
    e.detail.organizationUniqueId = organizationUniqueId;
    const resp = await backend.saveOutlet(e.detail);
    if (resp.errors) {
      return;
    }
    /*
    {
      name,
      number,
      billingContact: {
        firstName: billingContactFirstName,
        lastName: billingContactLastName,
        emailAddress: billingContactEmailAddress,
        mobileNumber: billingContactMobileNumber,
      },
    }
    */
    $goto(
      "/organizations/" + organizationUniqueId + "/outlets/" + resp.uniqueId
    );
  }
</script>

<PageTitle>Create Outlet</PageTitle>
<OutletEditor {organizationUniqueId} on:save={onSaveOutlet} />
