<script>
  import {
    Link,
    Grid,
    Column,
    Row,
    DataTableSkeleton,
    DataTable,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableBody,
    TableRow,
  } from "carbon-components-svelte";
  import IconAdd from "carbon-icons-svelte/lib/Add32";
  import {onMount, onDestroy} from "svelte";
  import {goto, params, route} from "@sveltech/routify";
  import {breadcrumbsStore, toolbarStore, userStore} from "../../../../../../stores";
  import {generateBreadcrumbs} from "../../../../../../utils";
  import backend from "../../../../../../backend";
  import PageTitle from "../../../../../../components/PageTitle.svelte";
  import EmptyState from "../../../../../../components/EmptyState.svelte";

  const headers = [
    {key: "uniqueID", value: "Unique ID"},
    {key: "firstName", value: "First Name"},
    {key: "lastName", value: "Last Name"},
    {key: "emailAddress", value: "Email Address"},
    {key: "mobilePhone", value: "Mobile Phone"},
    {key: "lastLoginTs", value: "Last Login", format: (v) => dayjs(v).format($userStore.regionalSettings.dateFormat)},
    {key: "lastLoginIp", value: "Last Login IP"},
    {key: "status", value: "Status"},
  ];

  const skeletonHeaders = headers.filter((x) => x.key !== "uniqueID").map((x) => x.value);

  let outletUniqueID;
  let organizationUniqueID;
  let rows;

  onMount(async () => {
    breadcrumbsStore.set(generateBreadcrumbs($route, $params));

    outletUniqueID = $params.outlet;
    organizationUniqueID = $params.organization;

    rows = await backend.getOutletUsers(outletUniqueID);

    toolbarStore.set([
      {
        title: "Users",
        icon: IconAdd,
        action: () => {
          $goto(`/organizations/${organizationUniqueID}/outlets/${outletUniqueID}/users/create`);
        },
      },
    ]);
  });

  onDestroy(() => {
    rows = null;
    toolbarStore.reset();
  });
</script>

<PageTitle>Users</PageTitle>

<Grid>
  <Row>
    <Column>
      {#if rows}
        {#if rows.length > 0}
          <DataTable {...$$props} {rows} {headers} let:props>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {#each props.headers.filter((x) => x.key != 'uniqueID') as header, i (header.key)}
                      <TableHeader>{header.value}</TableHeader>
                    {/each}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {#each props.rows as row, i}
                    <TableRow>
                      {#each row.cells as cell, j}
                        <!--
                        {#if j === 1}
                          <TableCell>                            
                            <Link
                              href={'/organizations/' + organizationUniqueID + '/outlets/' + outletUniqueID + '/users/' + row.cells[0].value}>
                              {row.cells[1].value}
                            </Link>                            
                          </TableCell>
                        {:else if headers[j].key !== 'uniqueID'}
                        -->
                        {#if headers[j].key !== 'uniqueID'}
                          <TableCell>{headers[j].format ? headers[j].format(cell.value) : cell.value || ''}</TableCell>
                        {/if}
                      {/each}
                    </TableRow>
                  {/each}
                </TableBody>
              </Table>
            </TableContainer>
          </DataTable>
        {:else}
          <EmptyState
            on:primaryAction={() => {
              $goto(`/organizations/${organizationUniqueID}/outlets/${outletUniqueID}/users/create`);
            }}
            title="No users found"
            body="Click <strong>Create User</strong> button to create a new user."
            primaryActionTitle="Create User"
            primaryActionIcon={IconAdd} />
        {/if}
      {:else}
        <DataTableSkeleton headers={skeletonHeaders} />
      {/if}
    </Column>
  </Row>
</Grid>
