<script>
  import {isLoading} from "svelte-i18n";
  import {Loading, ToastNotification} from "carbon-components-svelte";
  import {onMount} from "svelte";
  import {fly, fade} from "svelte/transition";
  import {Router} from "@sveltech/routify";
  import {routes} from "@sveltech/routify/tmp/routes";
  import {toastStore} from "./svekit/stores";
  import {userStore, loaderStore} from "./stores";
  import {initi18n} from "./i18n";
  import backend from "./backend";

  initi18n();

  toastStore.configure(4000);

  onMount(async () => {
    console.log("App.onMount");
    let res = await backend.getUserInfo();
    if (res.hasErrors) {
      return;
    }
    userStore.authenticated(res);
  });
</script>

<svelte:head>
  <style src="scss/main.scss">
  </style>
</svelte:head>

{#if $isLoading}
  Loading...
{:else}
  <Router {routes} />
{/if}

<div style="position:fixed;top:3rem; right:1rem; z-index:100000">
  {#each $toastStore as toast, i (toast)}
    <div in:fly={{y: 200, duration: 500}} out:fade>
      <ToastNotification
        {...toast}
        on:close={() => {
          toastStore.remove(i);
        }}
        style="margin-bottom: 1rem" />
    </div>
  {/each}
</div>

<Loading active={$loaderStore} description="Please wait..." />
