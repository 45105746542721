<script>
  import {locale, _} from "svelte-i18n";
  import {onMount} from "svelte";
  import {Button, Form, FormGroup, TextInput, PasswordInput, Grid, Row, Column} from "carbon-components-svelte";
  import {goto} from "@sveltech/routify";
  import Sveltidator from "svelte-validation";
  import {userStore} from "../../stores";
  import backend from "../../backend";
  import FieldErrors from "../../components/FieldErrors.svelte";
  import {usernameConstraints, passwordConstraints} from "../../validation";

  let emailAddress;
  let password;

  let sv = Sveltidator(
    () => {
      return {emailAddress, password};
    },
    {
      emailAddress: usernameConstraints,
      password: passwordConstraints,
    }
  );

  onMount(() => {});

  async function onFormSubmit(e) {
    e.preventDefault();
    sv.reset();
    const res = await backend.login({
      emailAddress: emailAddress,
      password,
      device: {
        fp: "123456",
      },
    });
    if (res.hasErrors) {
      console.log(res.errors, res.errorMessages);
      sv.setBackendErrors(res.errorMessages);
      /*
      sv.setBackendErrors({
        username: ["Username is already taken"],
        password: ["Password is not correct"],
      });
      */
      return;
    }
    $goto("/");
    /*
    
    */
    /*
    toast({
      message: "Hello There",
      position: "bottom-center",
      type: "is-success",
      dismissible: true,
      animate: { in: "fadeIn", out: "fadeOut" },
    });
    */
    //console.log(loginForm);

    //userStore.authenticated();
    //$goto("/");
  }
</script>

<Grid>
  <Row>
    <Column sm={8} md={{span: 4, offset: 3}} lg={{span: 3, offset: 4}}>
      <h2>{$_('pages.login.formTitle')}</h2>
      <div style="height: 1.5rem" />
      <Form margin="$spacing-03" on:submit={onFormSubmit}>
        <FormGroup>
          <TextInput
            bind:value={emailAddress}
            invalid={$sv.invalid.emailAddress}
            invalidText={$sv.invalid.emailAddress}
            labelText={$_('pages.login.username')}
            placeholder={$_('pages.login.usernamePlaceholder')} />
        </FormGroup>
        <FormGroup>
          <PasswordInput
            bind:value={password}
            invalid={$sv.invalid.password}
            invalidText={$sv.invalid.password}
            labelText={$_('pages.login.password')}
            placeholder={$_('pages.login.passwordPlaceholder')} />
        </FormGroup>
        <FormGroup>
          <Button disabled={!$sv.isFormValid} type="submit">{$_('pages.login.submitButton')}</Button>
        </FormGroup>
      </Form>
    </Column>
  </Row>
</Grid>

<!--
<Form id="loginForm" on:submit={onFormSubmit} class="box">
  <h3>Partner Login</h3>
  <FormGroup>
    <Label for="username">Username</Label>
    <Input
      bind:value={username}
      type="email"
      name="email"
      valid={$sv.valid.username}
      invalid={$sv.invalid.username}
      id="username"
      placeholder="Email address" />
    <FieldErrors field={$sv.invalid.username} />
  </FormGroup>
  <FormGroup>
    <Label for="password">Password</Label>
    <Input
      bind:value={password}
      valid={$sv.valid.password}
      invalid={$sv.invalid.password}
      type="password"
      name="password"
      id="password"
      placeholder="Your password" />
    <FieldErrors field={$sv.invalid.password} />
  </FormGroup>

  <div class="field">
    <label for="" class="checkbox">
      <input type="checkbox" /> Remember me
    </label>
  </div>
  <div class="field">
    <Button disabled={!$sv.isFormValid} color="primary">Login</Button>
  </div>
</Form>
-->
