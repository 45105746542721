<script>
  import {onMount, onDestroy} from "svelte";
  import {route, params, url} from "@sveltech/routify";
  import backend from "../backend";
  import {breadcrumbsStore} from "../stores";
  import {generateBreadcrumbs} from "../utils";
  import PageTitle from "../components/PageTitle.svelte";
  import Tiles from "../components/Tiles.svelte";

  let tiles = [
    {key: "organizations", title: "Organizations"},
    {key: "outlets", title: "Outlets"},
    {key: "clients", title: "Clients"},
    {key: "menuLaunches", title: "Menu Launches"},
  ];

  onMount(() => {
    breadcrumbsStore.set(generateBreadcrumbs($route, $params));

    backend.getDashboardStats().then((v) => {
      tiles.forEach((x) => {
        x.value = v[x.key];
      });
      tiles = tiles;
    });
  });

  onDestroy(() => {});
</script>

<PageTitle>Dashboard</PageTitle>
<Tiles items={tiles} />
