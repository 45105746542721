<script>
  import { Grid, Column, Row } from "carbon-components-svelte";
  import { url, route, params } from "@sveltech/routify";
  import { breadcrumbsStore } from "../stores";
  import { generateBreadcrumbs } from "../utils";
  import PageTitle from "../components/PageTitle.svelte";

  breadcrumbsStore.set(generateBreadcrumbs($route, $params));
</script>

<PageTitle>Change Password</PageTitle>

<Grid>
  <Row>
    <Column>Coming soon...</Column>
  </Row>
</Grid>
