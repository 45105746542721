import {toastStore} from "./svekit/stores";
import {userStore, loaderStore} from "./stores";
import api from "./api";
import {ERR_GENERIC, ERR_BAD_AUTH, ERR_SESSION_NOT_FOUND} from "./consts";
import {getBackendError} from "./i18n";

console.log("backend.js");

let counter = 0;
let requestMap = {};

const generateReqID = function () {
  const length = 16;
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

async function processRequest(requestFunc, payload, withLoader) {
  counter++;
  const requestNo = counter + 1;
  try {
    /*
    if (withLoader) {
      const intervalID = setTimeout(() => {
        loaderStore.set(true);
      }, 1000);
      requestMap[requestNo] = intervalID;
      console.log("Loader interval for request", requestNo, "id", intervalID);
    }
    */
    let resp = await requestFunc(payload);
    console.log("processRequest", "no", requestNo, resp);
    try {
      let obj = await resp.json();

      if (obj) {
        if (obj.errors) {
          obj.hasErrors = true;
          obj.errorMessages = {};

          for (var j = 0; j < obj.errors.length; j++) {
            var e = obj.errors[j];

            if (e.detail && e.code) {
              obj.errorMessages[e.detail] = [getBackendError(e.detail, e.code)];
            }

            if (e.code === ERR_GENERIC) {
              ``;
              toastStore.add("Error Occured", "Request cannot be processed", e.detail, "error");
              continue;
            } else if (e.code === ERR_BAD_AUTH || e.code === ERR_SESSION_NOT_FOUND) {
              userStore.loggedOut();
            }
          }
        }
      } else {
        obj = {};
      }
      return obj;
    } catch (err) {
      console.warn("JSON was not found in response");
    } finally {
      /*
      loaderStore.set(false);
      console.log("clearInterval for request", requestNo, "interval id", requestMap[requestNo]);
      clearTimeout(requestMap[requestNo]);
      */
    }
  } catch (err) {
    console.log(err, "[request payload]", payload);
    toastStore.add("Error Occured", "Request cannot be processed", err, "error");
  }
}

const backend = {
  async login(payload) {
    let obj = await processRequest(api.login, payload);
    if (!obj.hasErrors) {
      userStore.authenticated();
      window.localStorage.setItem("amp_token", obj.sessionToken);
    }
    return obj;
  },
  async logout() {
    let obj = await processRequest(api.logout);
    userStore.loggedOut();
    window.localStorage.removeItem("amp_token");
    return obj;
  },
  async getUserInfo(payload) {
    console.debug("backend.getUserInfo");
    let obj = await processRequest(api.getUserInfo, payload);
    return obj;
  },
  async getDashboardStats() {
    let obj = await processRequest(api.getDashboardStats);
    return obj;
  },
  async getOrganizationStats(id) {
    let obj = await processRequest(api.getOrganizationStats, id);
    return obj;
  },
  async saveOrganization(payload) {
    let obj = await processRequest(api.saveOrganization, payload);
    return obj;
  },
  async saveOutlet(payload) {
    let obj = await processRequest(api.saveOutlet, payload);
    return obj;
  },
  async saveOutletUser(payload) {
    let obj = await processRequest(api.saveOutletUser, payload);
    return obj;
  },
  async addUsersToOutlet(payload) {
    let obj = await processRequest(api.addUsersToOutlet, payload);
    return obj;
  },
  async getOrganization(payload) {
    let obj = await processRequest(api.getOrganization, payload);
    return obj;
  },
  async getOrganizations(payload) {
    let obj = await processRequest(api.getOrganizations, payload);
    return obj;
  },
  async getOrganizationUsers(payload) {
    let obj = await processRequest(api.getOrganizationUsers, payload);
    return obj;
  },
  async getOrganizationUsersNotInOutlet(payload) {
    let obj = await processRequest(api.getOrganizationUsersNotInOutlet, payload);
    return obj;
  },
  async getOutlets(payload) {
    let obj = await processRequest(api.getOutlets, payload);
    return obj;
  },
  async getOutlet(payload) {
    let obj = await processRequest(api.getOutlet, payload);
    return obj;
  },
  async getOutletUsers(payload) {
    let obj = await processRequest(api.getOutletUsers, payload);
    return obj;
  },
  async getOutletStats(payload) {
    let obj = await processRequest(api.getOutletStats, payload);
    return obj;
  },
  async getBreadcrumbTitle(payload) {
    let obj = await processRequest(api.getBreadcrumbTitle, payload, true);
    return obj;
  },
};

export default backend;
