<script>
  import {Grid, Column, Row, Tile} from "carbon-components-svelte";
  import {onMount, onDestroy} from "svelte";
  import IconAdd from "carbon-icons-svelte/lib/Add32";
  import IconEdit from "carbon-icons-svelte/lib/Edit32";
  import IconDataTable from "carbon-icons-svelte/lib/DataTable32";
  import IconInvoice from "carbon-icons-svelte/lib/Account32";
  import {params, route, goto} from "@sveltech/routify";
  import backend from "../../../backend";
  import {breadcrumbsStore, toolbarStore} from "../../../stores";
  import {generateBreadcrumbs} from "../../../utils";
  import PageTitle from "../../../components/PageTitle.svelte";
  import Tiles from "../../../components/Tiles.svelte";

  let orgUniqueID;
  let organization;
  let tiles = [
    {key: "outlets", title: "Outlets"},
    {key: "clients", title: "Clients"},
    {key: "menuLaunches", title: "Menu Launches"},
  ];

  onMount(() => {
    breadcrumbsStore.set(generateBreadcrumbs($route, $params));

    orgUniqueID = $params.organization;

    backend.getOrganization(orgUniqueID).then((v) => {
      organization = v;
    });

    backend.getOrganizationStats(orgUniqueID).then((v) => {
      tiles.forEach((x) => {
        x.value = v[x.key];
      });
      tiles = tiles;
    });

    toolbarStore.set([
      {
        title: "Outlets",
        icon: IconDataTable,
        action: () => {
          $goto("/organizations/" + orgUniqueID + "/outlets");
        },
      },
      {
        title: "Add Outlet",
        icon: IconAdd,
        action: () => {
          $goto("/organizations/" + orgUniqueID + "/outlets/create");
        },
      },
      {
        title: "Invoices",
        icon: IconInvoice,
        action: () => {
          $goto("/organizations/" + orgUniqueID + "/invoices");
        },
      },
      {
        title: "Edit",
        icon: IconEdit,
        action: () => {},
      },
    ]);
  });

  onDestroy(() => {
    toolbarStore.reset();
  });
</script>

<PageTitle object={organization} property="name" />
<Tiles items={tiles} />
