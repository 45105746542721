<script>
  import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    HeaderNav,
    HeaderPanelLinks,
    HeaderNavItem,
    HeaderNavMenu,
    HeaderUtilities,
    HeaderActionSearch,
    HeaderAction,
    HeaderPanelDivider,
    Modal,
    Header,
    SideNav,
    SideNavMenu,
    SideNavMenuItem,
    SideNavItems,
    SideNavLink,
    Content,
    Grid,
    Row,
    Column,
  } from "carbon-components-svelte";
  import SettingsAdjust20 from "carbon-icons-svelte/lib/SettingsAdjust20";
  import Help20 from "carbon-icons-svelte/lib/Help20";
  import ChangeCatalog16 from "carbon-icons-svelte/lib/ChangeCatalog16";
  import ManageProtection16 from "carbon-icons-svelte/lib/ManageProtection16";
  import Notification20 from "carbon-icons-svelte/lib/Notification20";
  import UserAvatar20 from "carbon-icons-svelte/lib/UserAvatar20";
  import AppSwitcher20 from "carbon-icons-svelte/lib/AppSwitcher20";
  import {onMount} from "svelte";
  import {fade} from "svelte/transition";
  import Icon from "mdi-svelte";
  import {mdiChevronLeft, mdiChevronRight} from "@mdi/js";
  import {goto, isActive, params, route} from "@sveltech/routify";
  import {userStore, breadcrumbsStore, toolbarStore} from "../stores";
  import {countBreadcrumbs} from "../utils";
  import backend from "../backend";

  let open = $$props.open;

  $: routeHistory = [route, ...(routeHistory || [])];
  $: cachedParams = {...(cachedParams || {}), ...params};

  route.subscribe((value) => {
    if ($userStore.authState === "not-authenticated" && value.path !== "/public/login") {
      console.log("***1");
      $goto("/public/login");
    }
  });

  userStore.subscribe((value) => {
    if ($userStore.authState === "not-authenticated" && $route.path !== "/public/login") {
      console.log("***2");
      $goto("/public/login");
    }
  });

  let isSideNavOpen = undefined;

  function toggleSidebar() {
    isSidebarOpen = !isSidebarOpen;
  }

  export let slug;
  let isSidebarOpen = true;
  let utilStates = {
    settings: false,
    notifications: false,
  };

  let iCatalog = {
    class: undefined,
    skeleton: false,
    render: ChangeCatalog16,
    title: "Catalog",
    tabindex: "0",
    focusable: false,
    style: undefined,
  };
  let iHelp = {
    class: undefined,
    skeleton: false,
    render: Help20,
    title: "Help",
    tabindex: "0",
    focusable: false,
    style: undefined,
  };
  let iAdjust = {
    class: undefined,
    skeleton: false,
    render: SettingsAdjust20,
    title: "Catalog",
    tabindex: "0",
    focusable: false,
    style: undefined,
  };
  let iProtection = {
    class: undefined,
    skeleton: false,
    render: ManageProtection16,
    title: "Catalog",
    tabindex: "0",
    focusable: false,
    style: undefined,
  };
  let iSwitcher = {
    class: undefined,
    skeleton: false,
    render: AppSwitcher20,
    title: "App Switcher",
    tabindex: "0",
    focusable: false,
    style: undefined,
  };
  let iAccount = {
    class: undefined,
    skeleton: false,
    render: UserAvatar20,
    title: "Account",
    tabindex: "0",
    focusable: false,
    style: undefined,
  };
  let iNotifications = {
    class: undefined,
    skeleton: false,
    render: Notification20,
    title: "Notifications",
    tabindex: "0",
    focusable: false,
    style: undefined,
  };
  let iSettings = {
    class: undefined,
    skeleton: false,
    render: SettingsAdjust20,
    title: "Settings",
    tabindex: "0",
    focusable: false,
    style: undefined,
  };

  onMount(async () => {
    console.log("MOUNT");
  });
</script>

<Header company="AvantMenu" platformName="Partner" href="/" expandedByDefault={false} bind:isSideNavOpen {...$$props}>
  <HeaderNav aria-label="AvantMenu Partner">
    {#if $userStore.authState === 'authenticated'}
      <HeaderNavMenu text="Organizations">
        <HeaderNavItem href="/organizations" text="View Organizations" />
        <HeaderNavItem href="/organizations/create" text="Add New Organization" />
      </HeaderNavMenu>
      <HeaderNavMenu text="Account">
        <HeaderNavItem href="/change-password" text="Change Password" />
        <HeaderNavItem href="/settings" text="Settings" />
        <HeaderNavItem
          href="javascript:void(0)"
          text="Log out"
          on:click={() => {
            open = true;
          }} />
      </HeaderNavMenu>
    {/if}
  </HeaderNav>
  <SideNav fixed bind:isOpen={isSideNavOpen}>
    <SideNavItems>
      <SideNavLink text="Dashboard" href="/" />
      <SideNavMenu text="Organizations">
        <SideNavMenuItem text="View Organizations" href="/organizations" />
        <SideNavMenuItem text="Add New Organization" href="/organizations/create" />
      </SideNavMenu>
      <SideNavMenu text="Account">
        <SideNavMenuItem text="Change Password" href="/change-password" />
        <SideNavMenuItem text="Settings" href="/settings" />
      </SideNavMenu>
      <SideNavLink
        text="Logout"
        href="javascript:void(0)"
        on:click={() => {
          open = true;
        }} />
    </SideNavItems>
  </SideNav>
  <HeaderUtilities>
    {#if $userStore.authState === 'authenticated'}
      <HeaderActionSearch />
      <HeaderAction type="Notifications" icon={iNotifications} bind:isOpen={utilStates.notifications}>
        <HeaderPanelLinks>
          <HeaderPanelDivider>Notifications</HeaderPanelDivider>
        </HeaderPanelLinks>
      </HeaderAction>
    {/if}
  </HeaderUtilities>
</Header>

<Content>
  <Grid>
    {#if $userStore.authState === 'authenticated'}
      <Row>
        <Column>
          <Breadcrumb>
            {#each $breadcrumbsStore as item}
              <BreadcrumbItem href={item.url}>
                {#await item.title then v}{v.title}{/await}
              </BreadcrumbItem>
            {/each}
          </Breadcrumb>
        </Column>
        <Column style="text-align:right">
          {#each $toolbarStore as item}
            <Button
              kind={item.kind || 'ghost'}
              size="small"
              icon={item.icon}
              on:click={() => {
                item.action();
              }}>
              {item.title}
            </Button>&nbsp;
          {/each}
        </Column>
      </Row>
    {/if}
  </Grid>
  {#if $userStore.authState === 'authenticated' || $route.path.startsWith('/public')}
    <div>
      <slot scoped={cachedParams} />
    </div>
  {/if}
</Content>

<Modal
  modalHeading="Logout?"
  primaryButtonText="Yes"
  secondaryButtonText="No"
  size="xs"
  bind:open
  on:click:button--secondary={() => {
    open = false;
  }}
  on:submit={async () => {
    await backend.logout();
    window.location.href = '/public/login';
    open = false;
  }}>
  <div />
  <p>Are you sure you want to logout?</p>
</Modal>
