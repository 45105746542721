<script>
  import {
    Accordion,
    AccordionItem,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Checkbox,
    ComboBox,
    ContentSwitcher,
    Form,
    FormGroup,
    HeaderNav,
    HeaderNavItem,
    HeaderNavMenu,
    Select,
    SelectItem,
    Switch,
    TextInput,
    TextArea,
    ToastNotification,
    Toggle,
    Header,
    SideNav,
    SideNavItems,
    SideNavLink,
    Content,
    Grid,
    Row,
    Column,
  } from "carbon-components-svelte";
  import { onMount, onDestroy } from "svelte";
  import { route, params, goto } from "@sveltech/routify";
  import { toastStore } from "svekit/stores";
  import backend from "../../backend";
  import { breadcrumbsStore } from "../../stores";
  import { generateBreadcrumbs } from "../../utils";
  import PageTitle from "../../components/PageTitle.svelte";
  import OrganizationEditor from "../../components/OrganizationEditor.svelte";

  onMount(() => {
    breadcrumbsStore.set(generateBreadcrumbs($route, $params));
  });

  async function onSaveOrganization(e) {
    const resp = await backend.saveOrganization(e.detail);
    if (resp.errors) {
      return;
    }
    /*
    {
      name,
      number,
      billingContact: {
        firstName: billingContactFirstName,
        lastName: billingContactLastName,
        emailAddress: billingContactEmailAddress,
        mobileNumber: billingContactMobileNumber,
      },
    }
    */
    $goto("/organizations/" + resp.uniqueID);
  }
</script>

<PageTitle>Create Organization</PageTitle>
<OrganizationEditor on:save={onSaveOrganization} />
