<script>
  import IconEdit from "carbon-icons-svelte/lib/Edit32";
  import IconUsers from "carbon-icons-svelte/lib/UserMultiple32";
  import {onMount, onDestroy} from "svelte";
  import {goto, params, route} from "@sveltech/routify";
  import {breadcrumbsStore, toolbarStore} from "../../../../../stores";
  import {generateBreadcrumbs} from "../../../../../utils";
  import backend from "../../../../../backend";
  import PageTitle from "../../../../../components/PageTitle.svelte";
  import Tiles from "../../../../../components/Tiles.svelte";

  let outletUniqueID;
  let organizationUniqueID;
  let outlet;

  let tiles = [
    {key: "clients", title: "Users"},
    {key: "menuLaunches", title: "Menu Launches"},
    {key: "clicks", title: "Clicks"},
  ];

  onMount(() => {
    breadcrumbsStore.set(generateBreadcrumbs($route, $params));

    outletUniqueID = $params.outlet;
    organizationUniqueID = $params.organization;

    backend.getOutlet(outletUniqueID).then((v) => {
      outlet = v;
    });

    backend.getOutletStats(outletUniqueID).then((v) => {
      tiles.forEach((x) => {
        x.value = v[x.key];
      });
      tiles = tiles;
    });

    toolbarStore.set([
      {
        title: "Edit",
        icon: IconEdit,
        action: () => {},
      },
      {
        title: "Users",
        icon: IconUsers,
        action: () => {
          $goto(`/organizations/${organizationUniqueID}/outlets/${outletUniqueID}/users`);
        },
      },
    ]);
  });

  onDestroy(() => {
    toolbarStore.reset();
  });
</script>

<PageTitle object={outlet} property="name" />
<Tiles items={tiles} />
