import {writable} from "svelte/store";

function createUserStore() {
  const {subscribe, set, update} = writable({
    authState: "none",
    regionalSettings: {
      dateFormat: "MMM D, YYYY HH:mm",
    },
  });

  return {
    subscribe,
    authenticated: () =>
      update((n) => {
        console.debug("userStore.authenticated");
        n.authState = "authenticated";
        return n;
      }),
    loggedOut: () =>
      update((n) => {
        console.debug("userStore.loggedOut");
        n.authState = "not-authenticated";
        return n;
      }),
    reset: () =>
      set({
        authState: "none",
        isAuthenticated: false,
        authChecked: false,
      }),
  };
}

function createBreadcrumbsStore() {
  const {subscribe, set, update} = writable([]);

  return {
    set,
    subscribe,
    update,
    reset: () => {
      set([]);
    },
    append: (item) => {
      update((items) => {
        const i = items.findIndex((x) => {
          return x.url === item.url;
        });

        if (i > -1) {
          items = items.splice(i + 2);
        }

        items = [...items, item];
        return items;
      });
    },
  };
}

function createToolbarStore() {
  const {subscribe, set, update} = writable([]);

  return {
    subscribe,
    set,
    update,
    reset: () => {
      set([]);
    },
  };
}

export const userStore = createUserStore();
export const breadcrumbsStore = createBreadcrumbsStore();
export const toolbarStore = createToolbarStore();
export const loaderStore = writable(false);
