import {writable, update} from "svelte/store";

function createToastStore() {
  const config = {
    timeout: 3000,
  };

  const {subscribe, set, update} = writable([]);

  return {
    subscribe,
    configure: (timeout) => {
      config.timeout = timeout;
    },
    add: (
      title,
      subtitle,
      caption,
      kind = "info",
      icon,
      hideCloseButton,
      iconDescription = "Closes notification",
      clickCallback
    ) =>
      update((n) => {
        console.log("toastStore.add");
        if (n.length > 5) {
          n.shift();
        }
        n = [
          ...n,
          {
            isVisible: true,
            title,
            subtitle,
            caption,
            kind,
            icon,
            hideCloseButton,
            iconDescription,
            timeout: config.timeout,
            clickCallback,
          },
        ];
        return n;
      }),
    remove: (index) =>
      update((n) => {
        if (index) {
          n = n.splice(index, 1);
        } else {
          n = n.splice(1);
        }
        return n;
      }),
    reset: () => {
      set([]);
    },
  };
}

const toastStore = createToastStore();

export {createToastStore, toastStore};
