<script>
  import { Grid, Row, Column, SkeletonText } from "carbon-components-svelte";

  export let object;
  export let property;
</script>

<style type="text/scss">
  @import "@carbon/layout/scss/spacing";
  .pageTitle {
    margin-top: $spacing-05;
    margin-bottom: $spacing-05;
  }
</style>

<Grid>
  <Row>
    <Column>
      <h1 class="pageTitle">
        {#if property}
          {#if !object}
            <SkeletonText heading={true} width="20rem" />
          {:else}{object[property]}{/if}
        {:else}
          <slot />
        {/if}
      </h1>
    </Column>
  </Row>
</Grid>
