<script>
  import {onMount} from "svelte";
  import {params, route, goto} from "@sveltech/routify";
  import {toastStore} from "../../../../../../svekit/stores";
  import backend from "../../../../../../backend";
  import {breadcrumbsStore} from "../../../../../../stores";
  import {generateBreadcrumbs} from "../../../../../../utils";
  import PageTitle from "../../../../../../components/PageTitle.svelte";
  import UserEditor from "../../../../../../components/UserEditor.svelte";

  let organizationUniqueId;
  let outletUniqueId;

  onMount(() => {
    breadcrumbsStore.set(generateBreadcrumbs($route, $params));
    organizationUniqueId = $params.organization;
    outletUniqueId = $params.outlet;
  });

  async function onSaveUser(e) {
    console.log("saveUser");
    const resp = await backend.saveOutletUser({outletUniqueId, ...e.detail});
    if (resp.hasErrors) {
      return;
    }
    toastStore.add("User created", "User created and added to the outlet", null, "success");
    $goto(`/organizations/${organizationUniqueId}/outlets/${outletUniqueId}/users/`);
  }

  async function onAddSelectedUsers(e) {
    console.log("onAddSelectedUsers", e);
    const resp = await backend.addUsersToOutlet({outletUniqueId, users: e.detail});
    if (resp.hasErrors) {
      return;
    }
    $goto(`/organizations/${organizationUniqueId}/outlets/${outletUniqueId}/users/`);
  }
</script>

<PageTitle>Create Outlet User</PageTitle>
<UserEditor on:save={onSaveUser} on:addSelectedUsers={onAddSelectedUsers} {outletUniqueId} />
