<script>
  import {Button} from "carbon-components-svelte";
  import {createEventDispatcher} from "svelte";

  const dispatch = createEventDispatcher();

  export let title = undefined;
  export let body = undefined;
  export let primaryActionTitle = undefined;
  export let primaryActionIcon = undefined;
  export let secondaryActionTitle = undefined;
</script>

<style type="scss">
  @import "@carbon/type/scss/styles";
  @import "@carbon/layout/scss/spacing";

  .title {
    @include carbon--type-style("productive-heading-03");
    margin-bottom: $spacing-02;
  }
  .body {
    @include carbon--type-style("body-short-02");
    margin-bottom: $spacing-06;
  }
</style>

{#if title}
  <div class="title">{title}</div>
{/if}
{#if body}
  <div class="body">
    {@html body}
  </div>
{/if}
{#if primaryActionTitle}
  <div>
    <Button
      icon={primaryActionIcon}
      on:click={() => {
        dispatch('primaryAction');
      }}>
      {primaryActionTitle}
    </Button>
  </div>
{/if}
{#if secondaryActionTitle}
  <div>{secondaryActionTitle}</div>
{/if}
